import React from 'react'
import Img from 'gatsby-image'
import styles from './gift.module.css'

export default ({ data }) => (
  <div className={styles.gift}>
    <Img className={styles.giftImage} alt="Ambi" fluid={data.giftImage.fluid} />
    <div className={styles.giftDetails}>      
      <p className={styles.giftTitle}>{data.nommer}</p>
      <p>
      Stort een gift op {data.bank},<br/>
      rek.nr. {data.nommer} t.n.v. {data.tenNameVan},<br/>
      altijd o.v.v. {data.vermeldingVanVoorbeeld}<br/>
      </p>
    </div>   
  </div>
    
)
