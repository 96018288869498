import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import GiftImage from '../components/gift'
import GiftStyles from '../components/gift.module.css'
import { FaHandHoldingHeart } from 'react-icons/fa';
import navStyles from '../components/navigation.module.css';
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Logo from '../components/logo'
import '../components/quote.css';
import ReactWordcloud from 'react-wordcloud';
import { Resizable } from "re-resizable";
import ImageGrid from "react-image-grid-animator";
import GiftCollageImage from '../components/giftImage'

class Gift extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    const [author] = get(this, 'props.data.allContentfulPerson.edges')   
    const [Rekening] = get(this, 'props.data.allContentfulRekening.edges')
    const quotes = get(this, 'props.data.allContentfulAanhaaling.nodes')
    const blogpostImage = get(this, 'props.data.allContentfulBlogPost.edges')        
    const words = [];
    const images =[];
    quotes.forEach(sentence => {
      
      sentence.text.split(' ').forEach(word => {
        const min = 30;
        const max = 70;
        const rand = min + Math.random() * (max - min);
        words.push({
          text: word,
          value: rand,
        })  
      });
      
    });
    blogpostImage.forEach(image => {
      images.push(image.node.heroImage.img.src)
    })
    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Logo author={author} />
        <div className="wrapper">
        <ul className="header-list">
          <li><div className={navStyles.iconimage}><FaHandHoldingHeart  className={navStyles.icon}/></div></li>
          <h2><u>O</u>ndersteun</h2>          
        </ul>
        <hr className="hr-repeat"></hr>
        <GiftImage data={Rekening.node} />                     
        <div>
          <Resizable
            defaultSize={{
              width: 600,
              height: 300
            }}
            className={GiftStyles.resizeStyle}>            
              <div className={GiftStyles.heartShape}><ReactWordcloud words={words} /></div>            
          </Resizable>                       
        </div>
        <div className="quote">
        <ImageGrid
            images={ images}
            visibleCount={5}
            interval={2000}
            animationItemcount={5}
            transitionType={"FADE_AND_SCALE"}
            transitionDuration={200}
            isActive={true}
          />
        </div>
        <div className="quote">
          <blockquote>
          <p>
            Dank je wel voor al jullie ondersteuning
          </p>
          <cite>Lea van der Riet-Luiten</cite>
        </blockquote>
        </div>        
      </div>
      </Layout>
    )
  }
}

export default Gift

export const pageQuery = graphql`
query GiftQuery {    
  allContentfulRekening(filter: { id: { eq: "49951702-8a4e-5cd3-b8dd-498e759fcd56" } }) {
    edges {
      node {
        bank          
        nommer
        tenNameVan
        vermeldingVanVoorbeeld
        giftImage: logo {
          fluid(              
            maxWidth: 100
            maxHeight: 100
            resizingBehavior: PAD            
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  allContentfulPerson(filter: { id: { eq: "e339d456-b3e8-5213-8d99-cf8ebb14a0a0" } }) {
    edges {
      node {
        name
        shortBio  {
          childMarkdownRemark {
            html
          }
        }
        headline
        title
        heroImage: image {
          fluid(
            maxWidth: 1180
            maxHeight: 480
            resizingBehavior: PAD            
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  allContentfulAanhaaling(sort: {fields: [publikasieDatum], order: DESC}, limit: 3) {
    nodes {
      text: aanhaaling      
    }
  }
  allContentfulBlogPost(sort: {fields: [publishDate], order: DESC}) {
    edges {
      node {
         heroImage {          
          img: fluid {
            src
          }
        }
      }
    }
  }
}`
